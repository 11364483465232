import {DataSource, LaneData, ScoreListEntry, TableConfiguration} from "../models/Models";


function TableView(props: TableViewProps){

    function getTableRows(){
        var listData:any[] = [];

        switch (props.tableConfiguration?.dataSource){
            case DataSource.Value0:
                listData = props.scoreList ?? [];
                break;
            case DataSource.Value1:
                listData = props.laneData ?? [];
                break;
            case DataSource.Value2:
                listData = props.scoreList ?? [];
                break;
        }

            return listData.map(data => {
                return <tr className={"d-flex"}>
                    {
                        props.tableConfiguration?.tableColumnConfigurations?.map(c => {
                            var subtableDataFields = c.dataField?.split(".") ??[];

                            return c.isSubTable ?
                                <td style={{
                                    fontSize: c.fontSize,
                                    fontWeight: c.bold ? "bold" : "normal",
                                    width: `${c.width}%`,
                                    textAlign: c.textAlign == "start" ? "start" : c.textAlign == "end" ? "end" : "center",
                                    paddingLeft: c.marginLeft ?? 0,
                                    paddingRight: c.marginRight ?? 0
                                }}>
                                    {
                                        data[subtableDataFields[0]].length > (c.subTableIndex??0) ?
                                            data[subtableDataFields[0]][c.subTableIndex??0][subtableDataFields[1]]
                                            : ""
                                    }
                                </td>
                                :
                                <td style={{
                                    fontSize: c.fontSize,
                                    fontWeight: c.bold ? "bold" : "normal",
                                    width: `${c.width}%`,
                                    textAlign: c.textAlign == "start" ? "start" : c.textAlign == "end" ? "end" : "center",
                                    paddingLeft: c.marginLeft ?? 0,
                                    paddingRight: c.marginRight ?? 0
                                }}>
                                    {
                                        data[c.dataField!]
                                    }
                                </td>
                        })
                    }
                </tr>
            });

        return <tr></tr>
    }

    return <div>
        <h1>{props.header}</h1>
        <div className={"container-fluid"}>
            <table className={"table table-striped"}>
                <thead>
                <tr className={"d-flex"}>
                    {
                        props.tableConfiguration?.tableColumnConfigurations?.map(c => {
                            return <th style={{
                                fontSize: c.fontSize,
                                width: `${c.width}%`,
                                textAlign: c.textAlign == "start" ? "start" : c.textAlign == "end" ? "end" : "center",
                                paddingLeft: c.marginLeft ?? 0,
                                paddingRight: c.marginRight ?? 0
                            }}>
                                {c.headerName}
                            </th>
                        })
                    }
                </tr>
                </thead>
                <tbody>
                {
                    getTableRows()
                }
                </tbody>
            </table>
        </div>
    </div>
}

interface TableViewProps {
    header: string;
    tableConfiguration: TableConfiguration | undefined;
    scoreList: ScoreListEntry[] | undefined | null;
    laneData: LaneData[] | undefined | null;
}

export default TableView;